<template>
  <reports :pageOptions="pageOptions"></reports>
</template>

<script>
import Reports from "@/components/occupancy/reports/Reports";

export default {
  name: "PredictionWeekday",
  components: {
    Reports
  },
  data() {
    return {
      pageOptions: {
        pageTitle: "occupancy.header.prediction-weekday",
        searchRouteName: "r_occupancy-prediction-weekday-search-filters",
        helpLinkName: "r_occupancy-help-online-prediction-weekday",
        helpSlug: "occupancy-prediction-weekly",
        filtersSection: "prediction_weekly",
        barWidth: 200,
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              display: false
            }
          }
        },
        chartOptionsY: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              ticks: {
                callback: function(value) {
                  return value;
                }
              }
            }
          }
        },
        legendLabels: ["real-value", "predicted-value"]
      }
    };
  }
};
</script>
